import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { HeroContent } from '@/components/common/Hero/HeroContent';
import { Image } from '@/components/common/image';
import { WistiaVideoPlayer } from '@/components/common/WistiaComponents/WistiaVideoPlayer';
import { HeroSection } from '@/components/layout/Section';

import type { HeroContentProps } from '@/components/common/Hero/HeroContent/HeroContent';
import type { SanitySplitImageHeroType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface SplitImageProps
  extends Omit<SanitySplitImageHeroType, 'heroContent'>,
    BlockWithAudience {
  heroContent: HeroContentProps;
}

export const SplitImage = ({
  backgroundColour,
  backgroundImage,
  foregroundImage,
  heroContent,
  pageAudience,
  isAudienceSwitcherEnabled,
  video,
}: SplitImageProps) => {
  const backgroundColourToken =
    backgroundColour?.token || '--background-primary';

  const hasImage = Boolean(foregroundImage.asset);
  const hasVideo = hasImage && video?.videoId;

  return (
    <HeroSection
      backgroundColour={backgroundColourToken}
      backgroundImage={backgroundImage}
      verticalPadding="default"
      className="split-image"
      priority
    >
      <InnerWrapper mobileOrientation={foregroundImage.mobileOrientation}>
        <LeftPanel>
          <HeroContent
            {...heroContent}
            backgroundColour={backgroundColourToken}
            pageAudience={pageAudience}
            isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          />
        </LeftPanel>
        <RightPanel
          mobileOrientation={foregroundImage.mobileOrientation}
          position={foregroundImage?.position ?? 'bottom'}
        >
          {hasVideo && (
            <WistiaVideoPlayer image={foregroundImage} wistiaPopover={video} />
          )}

          {hasImage && !hasVideo && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <Image
              {...foregroundImage}
              object-fit="contain"
              radius="l"
              priority
              sizes="(max-width: 960px) 100vw, 50vw"
            />
          )}
        </RightPanel>
      </InnerWrapper>
    </HeroSection>
  );
};

const InnerWrapper = styled.div<
  Pick<SplitImageProps['foregroundImage'], 'mobileOrientation'>
>`
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-large) 0;
  flex-direction: ${({ mobileOrientation }) =>
    mobileOrientation === 'above' ? 'column-reverse' : 'column'};

  ${screen.md} {
    flex-direction: row;
    gap: 0 var(--spacing-800);
    justify-content: space-between;
  }
`;

const LeftPanel = styled.div`
  width: 100%;
  padding-top: var(--spacing-large);

  ${screen.sm} {
    padding-top: var(--spacing-xx-large);
  }

  ${screen.md} {
    width: 50%;
    margin-bottom: var(--spacing-1500);
  }
`;

const RightPanel = styled.div<
  Pick<SplitImageProps['foregroundImage'], 'mobileOrientation' | 'position'>
>`
  width: ${({ mobileOrientation }) =>
    mobileOrientation === 'above' ? '40%' : '100%'};
  display: flex;
  padding-top: ${({ position, mobileOrientation }) => {
    if (position === 'bottom') {
      return 'var(--spacing-500)';
    }
    if (mobileOrientation === 'above') {
      return 'calc(64px + var(--spacing-500))';
    }
    return '0';
  }};

  padding-right: ${({ position }) => (position === 'right' ? '0' : 'initial')};
  flex-direction: column;
  justify-content: ${({ position }) =>
    position === 'bottom' ? 'flex-end' : 'center'};

  > div {
    height: ${({ mobileOrientation }) =>
      mobileOrientation === 'above' ? 'initial' : '400px'};

    img {
      object-fit: contain;
      object-position: center;
    }
  }

  ${screen.md} {
    width: 50%;

    > div {
      height: initial;
    }
  }
`;
